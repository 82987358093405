<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">查看物流</h1>
				<div class="clearfix"></div>
			</div>
			<div class="box-bd">
				<div class="top">
					<div class="state">
						物流状态：运输中
					</div>
					<div class="state">
						快递公司：顺丰快递
					</div>
					<div class="state">
						快递单号：98451632541236542
					</div>
				</div>
				<div class="bottom">
					<div class="title">物流追踪</div>
					<div class="wuliulist">
						<div class="wuliuxx" v-for="(item,index) in messagesList" :key="index">
							<p class="context">{{item.context}}</p> 
							<p class="time">{{item.time}}</p>
						</div>
					</div>
				</div>
			</div>
			
			<div class="order-empty" v-if="messagesList.length<=0">
				<!-- v-else -->
				<div class="empty">
					<h2>暂无商品的物流信息！</h2>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				query:{
					"LogisticsNo": "", //物流单号
					"ExpressCode": "" //快递code
				},
				queryData:{
					
				},
				ProImg: '',
				orderInfo:{},
				imgindex:'',
				init: false,
				result: null,
				messagesList: [],
				staArr: ['在途', '已揽收', '疑难', '已签收', '已退签', '派件中', '退回', '转单', '', '', '待清关', '清关中', '已清关', '清关异常', '收件人拒签'],
			};
		},
		created() {
			var option = this.$route.params
			if (option.wuliu) {
				this.query.LogisticsNo = option.wuliu
				
			}
			if (option.code) {
				this.query.ExpressCode = option.code
			}
			this.wuliu()
			
		},
		filters: {
			// 格式化物流信息
			contextFormatter(v){
				return v.replace(/\d{11}/, re => `<span class="phoneText">${re}</span>`)
			}
		},
		methods: {
			async wuliu(){
				const res = await this.postdata('/Order/ExpressInfo', this.query)
				if(res.code==200){
					this.dataHandler(JSON.parse(res.data))
				}
				else{
					this.notifyError(res.msg);
				}
			},
			getstatuStr(res) {
				var reStr = '';
				if (res != null && res.state != undefined && res.state != '') {
					var state = parseInt(res.state)
					reStr = this.staArr[state];
				}
				return reStr;
			},
			// 查询到数据后的处理函数
			dataHandler(res) {
				if (res != null) {
					if (res.result != undefined && res.result == false) {
						this.init = false
					} else {
						this.init = true
						this.result = res;
						// debugger
						if (this.ExpressName == null || this.ExpressName == 'null' || this.ExpressName == '') {
							this.ExpressName = res.com
						}
						this.messagesList = this.result.data;
						console.log(this.messagesList)
					}
				}
			},
			
		},

	}
</script>

<style scoped>
	.top .state{
		font-size: 18px;
		color: #333;
		font-weight: 400;
		line-height: 42px;
	}
	.bottom{
		margin-top: 16px;
	}
	.bottom .title{
		font-size: 18px;
		color: #333;
		font-weight: 400;
		line-height: 48px;
	}
	.bottom .wuliulist{
		position: relative;
	}
	.bottom .wuliulist .wuliuxx{
		font-size: 16px;
		font-weight: 400;
		color: #949494;
		position: relative;
		padding-left: 32px;
		margin-bottom: 16px;
	}
	.bottom .wuliulist .wuliuxx .time{
		margin-top: 6px;
	}
	.bottom .wuliulist .wuliuxx:last-child{
		background-color: #fff;
		z-index: 12;
	}
	.bottom .wuliulist .wuliuxx::before{
		content: '';
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: #b5b5b5;
		position: absolute;
		left: 4px;
		top: 0;
		z-index: 10;
	}
	.bottom .wuliulist .wuliuxx:first-child::before{
		content: '';
		border:4px solid #b5b5b5;
		background-color: #000;
		left: 0;
	}
	.bottom .wuliulist::after{
		content: '';
		position: absolute;
		left: 10px;
		top: 0;
		width: 2px;
		background-color: #efefef;
		height: 95%;
	}
</style>

<style scoped>
	/* 订单为空的时候显示的内容CSS */
	.userInfo .Personalbtn {
		margin-top: 16px;
	}

	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.box {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}

	.userInfo .text {
		width: 100px;
		font-size: 15px;
	}

	.userInfo .rt {}

	.userInfo .rt .input {
		border: 1px solid #bbb;
		font-size: 15px;
		line-height: 30px;
		padding: 0 6px;
		border-radius: 4px;
	}



	.box-bd {
		margin-top: 16px;
	}

	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}

	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #757575;
		cursor: pointer
	}

	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #ff6700
	}

	.hide {
		display: none !important
	}

	.uc-order-item {
		position: relative
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #ff6700;
		border-color: #ff6700;
		color: #fff;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	.next-table {
		width: 25%;
		position: relative;
		margin: 48px 0;
	}

	.next-table table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		background: #fff;
	}

	.next-table-header {
		margin-bottom: -20px;
		padding-bottom: 20px;
		overflow: auto;
		font-size: 12px;
	}

	.next-table th {
		padding: 0;
		background: #ebecf0;
		color: #333;
		text-align: left;
		font-weight: 400;
		border: 1px solid #dcdee3;
	}

	.next-table th .next-table-cell-wrapper {
		padding: 12px 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	.tAction .t-change {
		cursor: pointer;
	}

	.next-table-cell-wrapper .tAction {
		display: flex;
	}


	.next-table-body {
		font-size: 12px;
		position: relative;
	}

	.next-table-row {
		transition: all .1s linear;
		background: #fff;
		color: #333;
	}

	.next-table-cell.hovered,
	.next-table-row.hovered,
	.next-table-row.selected {
		background: #f2f3f7;
		color: #333;
	}

	.next-table table tr:first-child td {
		border-top-width: 0;
	}

	.next-table td {
		padding: 0;
		border: 1px solid #dcdee3;
	}

	.next-table td .next-table-cell-wrapper {
		padding: 12px 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	table .t-default,
	table .t-needUpdate {
		display: block;
		width: 80px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border: 1px solid #ff5000;
		border-radius: 3px;
		background: #ffd6cc;
		color: #f30;
	}

	table .t-delete,
	table .t-setDefault {
		cursor: pointer;
	}

	/* 订单为空的时候显示的内容CSS END */
</style>
